import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import CliIcon from 'svgs/technological-benefits/ic-cli.svg';
import DocumentIcon from 'svgs/technological-benefits/ic-document-code.svg';
import MessageIcon from 'svgs/technological-benefits/ic-message-programming.svg';
import TrendIcon from 'svgs/technological-benefits/ic-trend-down.svg';
import EfficiencyIcon from 'svgs/technologies/node-js/business-benefits-items/like-shapes.svg';
import OpportunityIcon from 'svgs/technologies/node-js/business-benefits-items/teacher.svg';
import LowerCostIcon from 'svgs/technologies/node-js/business-benefits-items/wallet-check.svg';
import LinkedinLogo from 'svgs/technologies/node-js/companies/logo-linkedin.svg';
import NetflixLogo from 'svgs/technologies/node-js/companies/logo-netflix.svg';
import PaypalLogo from 'svgs/technologies/node-js/companies/logo-paypal.svg';
import WallmartLogo from 'svgs/technologies/node-js/companies/logo-wallmart.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

const iconProps = {
    width: 42,
    height: 42,
};

export const BUSINESS_BENEFITS = [
    {
        title: 'node-js.businessBenefits.benefits.efficient.title',
        description: 'node-js.businessBenefits.benefits.efficient.description',
        icon: <EfficiencyIcon {...iconProps} />,
    },
    {
        title: 'node-js.businessBenefits.benefits.lowerCost.title',
        description: 'node-js.businessBenefits.benefits.lowerCost.description',
        icon: <LowerCostIcon {...iconProps} />,
    },
    {
        title: 'node-js.businessBenefits.benefits.opportunity.title',
        description:
            'node-js.businessBenefits.benefits.opportunity.description',
        icon: <OpportunityIcon {...iconProps} />,
    },
];

export const COMPANY_LOGOS = [
    {
        logo: <NetflixLogo />,
        description: 'node-js.realResults.companyDescription.0',
    },
    {
        logo: <WallmartLogo />,
        description: 'node-js.realResults.companyDescription.1',
    },
    {
        logo: <LinkedinLogo />,
        description: 'node-js.realResults.companyDescription.2',
    },
    {
        logo: <PaypalLogo />,
        description: 'node-js.realResults.companyDescription.3',
    },
];

export const SECTIONS_TRANSLATION = [
    'node-js.topics.codeConsulting',
    'node-js.topics.realResults',
    'node-js.topics.technologicalBenefits',
    'node-js.topics.objectiveAdvantages',
    'node-js.topics.hireDevelopers',
    'node-js.topics.businessBenefits',
    'node-js.topics.whyWorkWithMobileReality',
];

export const SECTIONS_ID = [
    'node-js-code-consulting',
    'node-js-real-results',
    'node-js-technological-benefits',
    'node-js-objective-advantages',
    'node-js-hire-developers',
    'node-js-business-benefits',
    'node-js-why-work-with-mobile-reality',
];

export const ADVANTAGES = [
    {
        index: 1,
        section: 'community',
        paragraphsCount: 1,
    },
    {
        index: 2,
        section: 'performance',
        paragraphsCount: 5,
        ulIndex: 2,
        ulItemsCount: 3,
    },
    {
        index: 3,
        section: 'libraries',
        paragraphsCount: 1,
    },
];

export const PERKS_LIST = [
    {
        title: 'node-js.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'node-js.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'node-js.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'node-js.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const TECHNOLOGICAL_BENEFITS = [
    {
        icon: <TrendIcon {...iconProps} />,
        text: 'node-js.technologicalBenefits.minimizeMistakes.title',
        description:
            'node-js.technologicalBenefits.minimizeMistakes.description',
    },
    {
        icon: <CliIcon {...iconProps} />,
        text: 'node-js.technologicalBenefits.fulfilledRequirements.title',
        description:
            'node-js.technologicalBenefits.fulfilledRequirements.description',
    },
    {
        icon: <DocumentIcon {...iconProps} />,
        text: 'node-js.technologicalBenefits.improveQuality.title',
        description: 'node-js.technologicalBenefits.improveQuality.description',
    },
    {
        icon: <MessageIcon {...iconProps} />,
        text: 'node-js.technologicalBenefits.codeReadability.title',
        description:
            'node-js.technologicalBenefits.codeReadability.description',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.NODE_JS],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'node-js.footer.button',
    },
    categoryBanner: {
        title: 'node-js.footer.title',
        description: 'node-js.footer.description',
        svg: JavascriptSvg,
    },
};
