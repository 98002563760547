import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import OpenJsCertificatesLogo from 'svgs/technologies/node-js/open-js-certificate.svg';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const SSubContainer = styled.div`
    display: flex;
    background-color: var(--gray-color-90);
    padding: 6.25rem 3.125rem;
    gap: 1rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        padding: 6.25rem 2.625rem;
    `}

    ${CONSTANTS.MEDIA.max600`
        padding: 5.25rem 1.5rem 6.25rem;
    `}
`;

const SLogoContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;

    ${CONSTANTS.MEDIA.max1024`
        justify-content: center;  
    `}

    ${CONSTANTS.MEDIA.max600`
        .certificates {
            height: 18.75rem;
        }
    `}
`;

const SContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;
`;

const SParagraph = styled(Paragraph)`
    white-space: pre-wrap;
`;

const STitle = styled(HeaderSecond)`
    font-weight: 400;
`;

const SLink = styled.a`
    color: var(--primary-color);
    text-decoration: unset;
`;

export const OpenJsSection = () => {
    return (
        <SContainer>
            <SSubContainer>
                <SLogoContainer>
                    <OpenJsCertificatesLogo className="certificates" />
                </SLogoContainer>
                <SContentContainer>
                    <div>
                        <STitle>
                            <FormattedMessage id="node-js.certifiedSpecialists.title" />
                        </STitle>
                        <HeaderSecond>
                            <FormattedMessage id="node-js.certifiedSpecialists.subTitle" />
                        </HeaderSecond>
                    </div>
                    <SParagraph>
                        <FormattedMessage id="node-js.certifiedSpecialists.whatDoesItMean" />
                    </SParagraph>
                    <SParagraph>
                        <FormattedMessage id="node-js.certifiedSpecialists.meaning" />
                    </SParagraph>
                    <SParagraph>
                        <FormattedMessage
                            id="node-js.certifiedSpecialists.readMore"
                            values={{
                                link: (chunks) => (
                                    <SLink
                                        href="https://openjsf.org/certification/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {chunks}
                                    </SLink>
                                ),
                            }}
                        />
                    </SParagraph>
                </SContentContainer>
            </SSubContainer>
        </SContainer>
    );
};
