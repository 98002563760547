import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Results } from 'components/card-section-template/components/results';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { arrayOf, bool, element, shape, string } from 'prop-types';

const SHeader = styled(HeaderSecond)`
    max-width: 70rem;
    font-size: 3rem;

    ${({ withMargin }) =>
        withMargin &&
        `
        margin-bottom: 5rem;
    `}
`;

const SDescription = styled.span`
    max-width: 30rem;
    margin-bottom: 4rem;
`;

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
`;

export const RealResultsSection = ({
    id,
    title,
    description,
    logos,
    margin,
}) => {
    return (
        <SContainer id={id} margin={margin}>
            <SHeader withMargin={!description}>
                <FormattedMessage id={title} />
            </SHeader>
            {description && (
                <SDescription>
                    <FormattedMessage id={description} />
                </SDescription>
            )}
            <Results results={logos} fillWidth />
        </SContainer>
    );
};

RealResultsSection.propTypes = {
    id: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    logos: arrayOf(
        shape({
            logo: element.isRequired,
            description: string,
        }),
    ).isRequired,
    margin: bool,
};

RealResultsSection.defaultProps = {
    margin: false,
};
