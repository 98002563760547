import React from 'react';
import { css } from 'styled-components';
import { SectionTilesSection } from 'modules/section-tiles-section';
import { arrayOf, node, shape, string } from 'prop-types';

export const TechnologicalBenefitsSection = ({
    id,
    data,
    title,
    paragraph,
}) => {
    return (
        <SectionTilesSection
            id={id}
            data={data}
            title={title}
            paragraph={paragraph}
            isParagraphFormattedPreviously={true}
            titleCss={css`
                max-width: 70rem;
                font-size: 3rem;
            `}
        />
    );
};

TechnologicalBenefitsSection.propTypes = {
    id: string.isRequired,
    data: arrayOf(
        shape({
            icon: node.isRequired,
            text: string.isRequired,
            description: string.isRequired,
        }),
    ).isRequired,
    subTitle: string.isRequired,
    descriptions: arrayOf(string).isRequired,
};
