import React from 'react';
import { NodeJsView } from 'views/node-js';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/technologies/node-js.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const NodeJsPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout location={location} messages={messages}>
            <NodeJsView {...{ faqData }} />
        </Layout>
    );
};

NodeJsPage.propTypes = {
    location: object.isRequired,
};

export default NodeJsPage;

export const Head = () => <SEO tags={TAGS.NODE_JS} />;

export const pageQuery = graphql`
    query NodeJsPageQuery {
        allContentfulFaq(filter: { slug: { eq: "technologies/node-js" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
